<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" content="明细详情"></el-page-header>
    <div class="content-box">
      <el-table :loading="loading" :data="tableData" :stripe="true" border fit>
        <el-table-column label="序号" width="70">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间"></el-table-column>
        <el-table-column prop="updateTime" label="核销时间"></el-table-column>
        <el-table-column prop="orderNo" label="订单编号"></el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="mobile" label="下单电话"></el-table-column>
        <el-table-column prop="amount" label="订单金额"></el-table-column>
        <el-table-column prop="writeOffAmount" label="推广佣金"></el-table-column>
        <el-table-column prop="actualIncome" label="实际收入"></el-table-column>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getMarketingIncomeDetailInfo } from '@/api/shop/statement/incomeDetails';
export default {
  data () {
    return {
      crumbs: new Map([
        ['特惠商城'], ['对账报表'], ['收入明细']
      ]),
      loading: true,
      tableData: [],
      limit: 1,
      page: 1,
      total: 0,
      id: '',
      from: '',
      dateStr: ''
    }
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    Object.assign(this, this.$route.query)
    this.getDetail()
  },
  methods: {
    // 获取收入明细详情
    getDetail() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { hotelId: this.hotelId, dateStr: this.dateStr };
      getMarketingIncomeDetailInfo(query, params).then(({ success, total, records }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getDetail()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getDetail()
    }
  }
}
</script>
